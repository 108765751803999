import Contacts from './components/everypagecomponents/contacts/contacts';
import Navbar from './components/everypagecomponents/navbar/navbar';
import './styles/main.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Mainpage from './components/mainpage/mainpage';
import Servicepage from './components/servicepage/servicepage';
import ScrollToTop from './utility';
import Portfoliopage from './components/portfoliopage/portfoliopage';
import Aboutcompany from './components/aboutcompany/aboutcompany';

function App() {
  const helmetContext = {};
  return (
    <>
    <HelmetProvider context={helmetContext}>
    <Router>
      <ScrollToTop/>
        <Navbar />
          <Routes>
              <Route path='/aboutcompany' element={<Aboutcompany/>}></Route>
              <Route path='/ee/aboutcompany' element={<Aboutcompany/>}></Route>
              <Route path='/' element={<Mainpage/>}></Route>
              <Route path='/ee/' element={<Mainpage/>}></Route>
              <Route path='/services/:id' element={<Servicepage/>}></Route>
              <Route path='/ee/services/:id' element={<Servicepage/>}></Route>
              <Route path='/portfolio' element={<Portfoliopage/>}></Route>
              <Route path='/ee/portfolio' element={<Portfoliopage/>}></Route>
          </Routes>
        <Contacts/>
    </Router>
    </HelmetProvider>
    </>
  );
}

export default App;
