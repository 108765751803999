import React from 'react';
import './style.css';
import { advantageDataee, advantageDataru } from './advantagedata';

function Advantages(){

    let data = advantageDataru
    let prefix = 'ru'
    if((window.location.pathname).includes("/ee")){
        data = advantageDataee
        prefix = 'ee'
    }

    return(
    <>
        <section id='advantageclass' className='advantageBlock'>
            <div className='advantageContainer'>
                <div className='advantageTitle'>
                    <h2 className='advantageHeader'>{prefix === 'ru' ? 'Почему выбирают нас' : 'Miks valida meid'}</h2>
                </div>
                <div className='advantageCards'>
                {data.map((item, index) => {
                    let index2 = index+200
                    return (
                        <div key={index2} className='advantageCard'>
                            <div className='advantageCard_icon'><img alt='card icon' src={item.icon}/></div>
                            <h2 className='advantageCard_header2'>{item.subject}</h2>
                            <h3 className='advantageCard_header3'>{item.description}</h3>
                        </div>
                    )
                })}
                </div>
            </div>
        </section>
    </>
    );
}

export default Advantages;