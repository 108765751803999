import foto from './../../../photo/ithumbup.svg'
import book from './../../../photo/ibook.svg'
import lock from './../../../photo/ilock.svg'
import wallet from './../../../photo/iwallet.svg'

export const advantageDataru = [
    {
        subject: 'Качественный дизайн и креативность',
        description: 'Сад, который действительно будет жить. Это комбинация ботаники, агротехники, дизайна, колористики, технологии и инженерных решений. Способность представить уникальные и креативные концепции, соответствующие потребностям клиента и особенностям местности.',
        icon: foto
    },
    {
        subject: 'Технические знания',
        description: 'Понимание технических аспектов, таких как дренаж, ирригация, освещение и другие инфраструктурные элементы.',
        icon: book
    },
    {
        subject: 'Умение работать с бюджетом',
        description: 'Эффективное управление бюджетом проекта, нахождение баланса между амбициозными идеями и реальными ресурсами клиента.',
        icon: wallet
    },
    {
        subject: 'Гарантии',
        description: 'Создаем стильные и функциональные сады и берем за них ответственность. Мы берем на сезонное обслуживание ваши сады.',
        icon: lock
    }
]

export const advantageDataee = [
    {
        subject: 'Kvaliteetne disain ja loovus',
        description: 'Aed, mis tõesti elab. See on kombinatsioon botaanikast, põllumajandustehnoloogiast, disainist, tehnoloogiast ja insenertehnilistest lahendustest. Võimalus esitada ainulaadseid ja loomingulisi kontseptsioone, mis vastavad kliendi vajadustele.',
        icon: foto
    },
    {
        subject: 'Tehnilised teadmised',
        description: 'Tehniliste aspektide mõistmine, nagu drenaaž, niisutus, valgustus ja muud infrastruktuuri elemendid.',
        icon: book
    },
    {
        subject: 'Võimalus töötada eelarvega',
        description: 'Projekti eelarve efektiivne juhtimine, tasakaalu leidmine ambitsioonikate ideede ja reaalsete kliendiressursside vahel.',
        icon: wallet
    },
    {
        subject: 'Garantiid',
        description: 'Loome stiilseid ja funktsionaalseid aedu ning vastutame nende eest. Pakume teie aedadele hooajalist hooldust.',
        icon: lock
    }
]