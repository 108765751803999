
export const aboutusDataru = [
    {
        text: 'О нас'
    },
    {
        text: 'Давайте знакомиться!'
    },
    {
        text: 'Меня зовут Илонна Борисова и я руководитель ландшафтной мастерской Greenera.'
    },
    {
        text: 'Мой профессиональный путь в ландшафтном дизайне начался с углубленного изучения теоретических основ ландшафтного дизайна, приобретения практических навыков в создании проектов и их реализации. Я постоянно стремлюсь к обучению, изучая последние тенденции и передовые методы в этой области.'
    },
    {
        text: 'В своей работе я активно использую современные технологии и визуализации, чтобы мои клиенты могли ясно представить, как будет выглядеть конечный результат. Я готова к постоянному росту и развитию, открывая для себя новые аспекты ландшафтного дизайна и наращивая свой опыт.'
    },
    {
        text: 'Моя страсть к природе и эстетике вдохновляет меня создавать уникальные и уютные места, которые сочетают в себе красоту и функциональность.'
    },
    {
        text: 'Каждый новый проект – это профессиональный вызов и возможность для творческого самовыражения в этой захватывающей области.'
    },
    {
        text: 'Мой подход основан на том, чтобы не просто проектировать участки, а создавать их живыми и функциональными, приносящими радость и пользу своим обитателям.'
    },
    {
        text: 'Я стремлюсь к тому, чтобы каждый проект был уникальным, отражая стиль и потребности клиента.'
    },
    {
        text: 'В моей команде работают профессионалы: ландшафтные строители, садовники и специалисты по системам автополива и освещения.'
    }
]


export const aboutusDataee = [
    {
        text: 'Meist'
    },
    {
        text: 'Saame tuttavaks!'
    },
    {
        text: 'Minu nimi on Ilonna Borissova ja olen Greenera maastikustuudio juhataja.'
    },
    {
        text: 'Minu erialane tee maastikukujunduses sai alguse maastikukujunduse teoreetiliste aluste süvendatud õppimisest, praktiliste oskuste omandamisest projektide loomisel ja nende teostamisel. Püüan end pidevalt harida, õppides valdkonna uusimaid trende ja parimaid tavasid.'
    },
    {
        text: 'Kasutan oma töös kaasaegseid tehnoloogiaid ja visualiseerimisi, et kliendid saaksid selgelt ette kujutada, milline lõpptulemus välja näeb. Olen valmis pidevaks kasvuks ja arenguks, avastamaks maastikukujunduses uusi tahke ja laiendades oma kogemusi.'
    },
    {
        text: 'Minu kirg looduse ja esteetika vastu inspireerib mind looma ainulaadseid ja hubaseid ruume, milles on ühendatud ilu ja funktsionaalsus.'
    },
    {
        text: 'Iga uus projekt on professionaalne väljakutse ja loova väljenduse võimalus selles põnevas valdkonnas.'
    },
    {
        text: 'Minu lähenemine ei põhine pelgalt alade kujundamisel, vaid nende loomisel elusate ja funktsionaalsetena, pakkudes nende elanikele rõõmu ja kasu.'
    },
    {
        text: 'Püüan selle poole, et iga projekt oleks unikaalne, peegeldades kliendi stiili ja vajadusi.'
    },
    {
        text: 'Minu meeskond koosneb professionaalidest: maastikuehitajad, aednikud ning automaatsete kastmis- ja valgustussüsteemide spetsialistid.'
    }
]