import React from 'react';
import './style.css';
import {ProtfolioSlider} from '../carousel/carousel';
import { HashLink } from 'react-router-hash-link';

function Portfolio(){

    const [hydrated, setHydrated] = React.useState(false);

	React.useEffect(() => {
		// This forces a rerender, so the date is rendered
		// the second time but not the first
		setHydrated(true);
	}, []);

	if (!hydrated) {
		// Returns null on first render, so the client and server match
		return null;
	}

    let prefix = ''
    if((window.location.pathname).includes("/ee")){
        prefix = '/ee'
    }

    return(
    <>
        <section suppressHydrationWarning={true} id='portfolioId' className='portfolioblock'>
            <div className='portfolioContainer'>
                <div className='portfolioTitle'>
                    {prefix === '' ? 'Портфолио' : 'Portfolio'}
                </div>
                <div className='portfolioCardsblock'>
                    <ProtfolioSlider/>
                </div>
                <div className='portfoliobutton'>
                <HashLink smooth to={prefix + '/portfolio'}><button className='portfoliobtn'>{prefix === '' ? 'Посмотреть больше' : 'Vaata rohkem'}</button></HashLink>
                </div>
            </div>
        </section>
    </>
    );
}

export default Portfolio;