import React from 'react';
import './style.css';
import { aboutcompanyDataru, aboutcompanyDataee } from './../../aboutcompanydata';
import image1 from './../../../../photo/aboutuspage/realization.jpg'
import image2 from './../../../../photo/aboutuspage/garden.jpg'
import image3 from './../../../../photo/aboutuspage/design.jpg'


function Aboutcompanysecondslide(){

    let aboutcompanyData

    if(/ee/.test(window.location.pathname)){
        aboutcompanyData = aboutcompanyDataee
    }else{
        aboutcompanyData = aboutcompanyDataru
    }
    
    return(
    <>
    <div className='ACfolder'>
        <div className='ACslide'>
            <div className='aboutcompanypaper'>
            <div className='ACTlist'>
                    <div className='ACTlistunit'>
                        <img src={image1} alt='altname'></img>
                        <h2>{aboutcompanyData[7]}</h2>
                    </div>
                    <div className='ACTlistunit'>
                        <img src={image2} alt='altname'></img>
                        <h2>{aboutcompanyData[8]}</h2>
                    </div>
                    <div className='ACTlistunit'>
                        <img src={image3} alt='altname'></img>
                        <h2>{aboutcompanyData[9]}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default Aboutcompanysecondslide;