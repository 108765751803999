import React from 'react';
import Serviceunit from './service/service';


function Servicepage(){

    return(
    <>
        <Serviceunit/>
    </>
    );
}


export default Servicepage;