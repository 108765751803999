import consultation from './../../../photo/image1.webp'
import landscaping from './../../../photo/Озеленение.webp'
import design from './../../../photo/image3b.webp'
import seasonal from './../../../photo/Сезонноеоформление.webp'
import garden from './../../../photo/./Portfolio/IMG_5-9593.webp'
import lawn from './../../../photo/Устройствогазона.webp'

import hatdesign from './../../../photo/image3s.jpg'
import hatconsultation from './../../../photo/consultationh.jpg'
import hatlandscaping from './../../../photo/landscapeh.jpg'
import hatseasonal from './../../../photo/seasonalh.jpg'
import hatgarden from './../../../photo/gardencareh.jpg'
import hatlawn from './../../../photo/lawnh.jpg'


export const serviceDataru = [
    {
        subject: 'Проектирование',
        description: [
            'Разработаем ландшафтный проект с полной технической документацией.',
            'Рассчитаем бюджет вашего проекта.',
            'Спроектируем отдельные элементы сада: оформим парадную зону, создадим уютное пространство для тихого отдыха или отдельные цветники.',
            'Проектирование озеленения на террасе или балконе.'
        ],
        path: '/services/Design',
        icon: design,
        unithat: hatdesign,
        service: [
            'Наша команда дизайнеров и инженеров предложит вам концептуальное проектирование для будущего сада, учитывая все потенциальные особенности вашего участка, а также ваши индивидуальные предпочтения и финансовые возможности. Наши квалифицированные специалисты разработают полный набор рабочих документов, соответствующих высоким стандартам качества.'
        ],
        pricesID: 1
    },
    {
        subject: 'Озеленение и благоустройство',
        description: [
            'Внимательно подберем посадочный материал в лучших садовых центрах и питомниках.',
            'Создадим современные и функциональные цветочные композиции.',
            'Сервисное обслуживание после завершения проекта.',
            'Мы гарантируем строгое соблюдение технологии при посадке.'
        ],
        path: '/services/Landscaping',
        icon: landscaping,
        unithat: hatlandscaping,
        service: [
            'Наша высококвалифицированная команда специалистов обеспечит полную реализацию вашего ландшафтного проекта, используя современные и надежные технологии в строительно-монтажных и посадочных работах. Все этапы проекта осуществляются под строгим руководством наших опытных специалистов.',
            'Наша услуга гарантирует не только высокий профессионализм и комплексный подход, но и надежность каждой выполненной работы. Доверьтесь нам, и мы превратим ваши идеи в великолепную реальность, придав вашему проекту неповторимый характер и стойкость.'
        ]
    },
    {
        subject: 'Уход за садом',
        description: [
            'Весь спектр работ по уходу за садом.',
            'Отдельное внимание специалист уделит вашему газону: вам будет предоставлен подробный график ухода, а также конкретная стратегия восстановления для вашего газонного покрытия.',
            'Уход за грядками и теплицей.',
            'Подготовка сада к приезду на выходные или к возвращению из отпуска.',
            'Подготовка сада к зиме и весенние работы в саду.',
            'Сервисное обслуживание в течение всего года.',
            'Уборка и поддержание порядка.'
        ],
        path: '/services/Garden_care',
        icon: garden,
        unithat: hatgarden,
        service: [
            'Мы с особой тщательностью подберем, приобретем и подготовим растения для посадки. А также грамотно организуем зоны для стационарных и контейнерных цветников, создадим огород и плодовый сад. Бригада специалистов по озеленению может проводить сервисные работы, включая регулярное или весенне-осеннее обслуживание сада, при этом гарантируя строгое соблюдение всех технологий посадки и ухода.'
        ]
    },
    {
        subject: 'Консультация и дистанционное проектирование',
        description: [
            'В рамках консультации обсуждаются потребности клиента, его предпочтения, а также особенности местности. Дизайнер предоставит конструктивные идеи, советы по выбору растений, материалов и элементов декора, помогая клиенту разработать общую концепцию для улучшения ландшафта. Эта услуга включает в себя рекомендации по размещению элементов, выбору цветовой палитры и другие ключевые аспекты, необходимые для успешного начала проекта ландшафтного дизайна.',
            'Дистанционное проектирование предоставляет клиентам возможность получить экспертные советы и разработать проект без личного присутствия. Это включает в себя удаленные консультации онлайн, обсуждение потребностей клиента, и предоставление проектных решений, в том числе чертежей, планов и рекомендаций. Такой подход обеспечивает удобство для клиентов и позволяет получить качественные результаты.',
        ],
        path: '/services/Consultation',
        icon: consultation,
        unithat: hatconsultation,
        service: [
            'Консультация с выездом на место — это персональное взаимодействие с ландшафтным дизайнером на самом участке. Она включает в себя анализ территории, обсуждение желаемых изменений, выявление особенностей и целей проекта, а также предоставление конкретных рекомендаций и идей, приспособленных к уникальным характеристикам местности.',
            'Дистанционное проектирование — это процесс разработки ландшафтного дизайна, который осуществляется удаленно, без необходимости физического присутствия на участке. Включает в себя виртуальные туры, обмен идеями через средства связи, создание эскизов, визуализаций и предоставление технической документации, обеспечивая клиентов полными дизайнерскими решениями, даже находясь вдали от объекта.'
        ]
    },
    {
        subject: 'Сезонное оформление',
        description: [
            'Создание цветочных композиций и использование декоративных элементов, которые подчеркивают уникальные черты и красоту каждого сезона.',
            'Цветочное оформление террас, балконов и входных групп.',
            'Осеннее и зимнее оформление любых зон.'
        ],
        path: '/services/Seasonal_decoration',
        icon: seasonal,
        unithat: hatseasonal,
        service: [
            'Сезонное оформление – это процесс изменения и декорирования участка или его части в зависимости от времени года. Он включает в себя использование различных растений, цветов, декора и элементов дизайна, чтобы создать атмосферу, соответствующую текущему сезону. Например, весеннее оформление может включать яркие цветы и свежую зелень, а зимнее – украшение элементами, связанными с праздничным временем года.',
            'Сезонное оформление балконов и террас - это креативный и эффективный способ придать вашему пространству индивидуальный и уютный вид в зависимости от времени года.'
        ]
    },
    {
        subject: 'Устройство рулонного газона',
        description: ['Создание и уход за газонами, включая укладку рулонного газона, удобрение, аэрацию и регулярную стрижку для создания идеального газонного покрытия.'],
        path: '/services/Rolled_lawn',
        icon: lawn,
        unithat: hatlawn,
        service: [
            'Рулонный газон — готовый газон, представленный в виде рулонов, готовых для укладки на подготовленную поверхность. Преимущества включают мгновенный эстетичный вид, равномерность, минимизацию сорняков, устойчивость к засухе и быстрое восстановление после повреждений. Удобен в использовании, подходит для сезонной укладки и обеспечивает эффективное использование воды. Это популярное решение в ландшафтном дизайне для мгновенного создания ухоженных газонов.'
        ]
    }
]

export const serviceDataee = [
    {
        subject: 'Proekteerimine',
        description: [
            'Töötame välja maastikuprojekti koos täieliku tehnilise dokumentatsiooniga.',
            'Arvutame välja teie projekti eelarve.',
            'Kujundame aia üksikuid elemente: loome hubase ruumi vaikseks lõõgastumiseks või lillepeenraid.',
            'Terrassil või rõdul haljastuse kujundamine.'
        ],
        path: '/ee/services/Design',
        icon: design,
        unithat: hatdesign,
        service: [
            'Meie disainerite ja inseneride meeskond pakub teile teie tulevase aia ideekavandi, võttes arvesse teie aia kõiki võimalikke omadusi, samuti teie individuaalseid eelistusi ja rahalisi võimalusi. Meie kvalifitseeritud spetsialistid töötavad välja täieliku töödokumentide komplekti.'
        ],
        pricesID: 1
    },
    {
        subject: 'Haljastus',
        description: [
            'Istutusmaterjali hoolikalt valime parimatest aianduskeskustest ja puukoolidest.',
            'Teenindus pärast projekti lõppu.',
            'Garanteerime istutamise ajal tehnoloogia range järgimise.'
        ],
        path: '/ee/services/Landscaping',
        icon: landscaping,
        unithat: hatlandscaping,
        service: [
            'Meie kõrgelt kvalifitseeritud spetsialistide meeskond tagab teie maastikuprojekti täieliku elluviimise, kasutades ehitus-, paigaldus- ja istutustöödel kaasaegseid ja töökindlaid tehnoloogiaid. Kõik projekti etapid viiakse läbi meie kogenud spetsialistide range juhendamise all.',
            'Meie teenus garanteerib mitte ainult kõrge professionaalsuse ja tervikliku lähenemise, vaid ka iga tehtud töö usaldusväärsuse. Usaldage meid, et muudame teie ideed vapustavaks reaalsuseks, andes teie projektile ainulaadse iseloomu ja vastupidavuse.'
        ]
    },
    {
        subject: 'Aiahooldus',
        description: [
            'Spetsialist pöörab teie murule erilist tähelepanu: teile esitatakse üksikasjalik hooldusgraafik ja konkreetne muru taastamisstrateegia.',
            'Peenarde ja kasvuhoonete eest hoolitsemine.',
            'Aia ettevalmistamine nädalavahetuseks või puhkuselt naasmiseks.',
            'Aia talve ettevalmistamine.',
            'Teenindus aastaringselt.',
            'Koristamine ja korra hoidmine.'
        ],
        path: '/ee/services/Garden_care',
        icon: garden,
        unithat: hatgarden,
        service: [
            'Erilise hoolega valime ja ostame taimi istutamiseks. Korraldame asjatundlikult ka statsionaarsete ja konteinerlillepeenarde alad, rajame juurviljaaia ja viljapuuaia. Haljastusspetsialistide meeskond saab teostada hooldustöid, sealhulgas korralist või kevad-sügisest aiahooldust, tagades samas kõigi istutus- ja hooldustehnoloogiate range järgimise.'
        ]
    },
    {
        subject: 'Konsultatsioon ja kaugprojekteerimine',
        description: [
            'Konsultatsiooni käigus arutatakse läbi kliendi vajadused, eelistused ja kohalikud eripärad. Disainer annab konstruktiivseid ideid, nõustab taimede, materjalide ja dekoratiivsete elementide valikul, aidates kliendil välja töötada maastiku täiustamise üldise kontseptsiooni. See teenus sisaldab soovitusi elementide paigutuse, värvipaleti valimise ja muude oluliste aspektide kohta, mis on vajalikud teie haljastusprojekti edukaks käivitamiseks.',
            'Kaugprojekteerimin annab klientidele võimaluse saada asjatundlikku nõu ja arendada projekti ilma isiklikult kohal viibimata. See hõlmab veebipõhiseid kaugkonsultatsioone, kliendi vajaduste arutamist ja projekteerimislahenduste, sealhulgas jooniste, plaanide ja soovituste pakkumist. Selline lähenemine pakub klientidele mugavust ja võimaldab saada kvaliteetseid tulemusi.',
        ],
        path: '/ee/services/Consultation',
        icon: consultation,
        unithat: hatconsultation,
        service: [
            'Kohapealne konsultatsioon on isiklik suhtlus maastikukujundajaga kohapeal. See hõlmab piirkonna analüüsimist, soovitud muudatuste arutamist, projekti tunnuste ja eesmärkide kindlaksmääramist ning konkreetsete soovituste ja ideede esitamist, mis on kohandatud piirkonna ainulaadsetele omadustele.',
            'Kaugprojekteerimine on maastikukujunduse väljatöötamise protsess, mis viiakse läbi eemalt, ilma et oleks vaja kohapeal füüsilist kohalolekut. Sisaldab virtuaaltuure, ideede jagamist suhtluse kaudu, visandite loomist, renderdamist ja tehnilise dokumentatsiooni pakkumist, klientidele terviklike disainilahenduste pakkumist.'
        ]
    },
    {
        subject: 'Hooajaline kaunistus',
        description: [
            'Loome lilleseadeid ja kasutame dekoratiivseid elemente, mis tõstavad esile iga aastaaja ainulaadsed omadused ja ilu.',
            'Terrasside, rõdude ja sissepääsualade lilleline kaunistus.',
            'Mis tahes tsoonide sügis- ja talvine kaunistamine.'
        ],
        path: '/ee/services/Seasonal_decoration',
        icon: seasonal,
        unithat: hatseasonal,
        service: [
            'Hooajaline kaunistamine on aeda või selle osa muutmise ja kaunistamise protsess sõltuvalt aastaajast. See hõlmab erinevate taimede, lillede, sisekujunduse ja disainielementide kasutamist, et luua praegusele hooajale sobiv õhkkond. Näiteks võivad kevadkaunistused sisaldada eredaid lilli ja värsket rohelust, talvised kaunistused aga pühadehooajaga seotud kaunistusi.',
            'Rõdude ja terrasside hooajaline kaunistamine on loominguline ja tõhus viis anda oma ruumile isikupärane ja hubane välimus olenevalt aastaajast.'
        ]
    },
    {
        subject: 'Rullmuru paigaldus',
        description: [
            'Muru rajamine ja hooldamine, sealhulgas mururullide paigaldamine, väetamine, õhutamine ja regulaarne niitmine täiusliku murupinna loomiseks.'
        ],
        path: '/ee/services/Rolled_lawn',
        icon: lawn,
        unithat: hatlawn,
        service: [
            'Rullmuru on muru, mis on rullide kujul ja on valmis ettevalmistatud pinnale laotamiseks. Eelised hõlmavad kohest esteetikat, ühtlust, umbrohtude minimeerimist, põuataluvust ja kiiret kahjustustest taastumist. Lihtne kasutada, sobib hooajaliseks paigalduseks ja tagab tõhusa veekasutuse. See on populaarne haljastuslahendus kaunite muruplatside koheseks loomiseks.'
        ]
    }
]
