import React from 'react';
import './style.css';
import { aboutusDataee, aboutusDataru } from './aboutusdata';

function Aboutus(){
    let data = aboutusDataru
    if((window.location.pathname).includes("/ee/")){
        data = aboutusDataee
    }

    return(
    <>
    <section id='aboutusID' className='aboutusBlock'>
        <div className='aboutusContainer'>
            <div className='aboutusTitle'>
                <h2 className='aboutusTitleheader'>{data[0].text}</h2>
            </div>
            <div className='aboutusMainbox'>
                <div className='aboutusPhoto'></div>
                <div className='aboutsusTextbox'>
                    <h2 className='aboutsusTextbox__title'>{data[1].text}</h2>
                    <h3 className='aboutsusTextbox__abzac'>
                    {data.map((item, index) => {
                        if(index < 2){
                            return ''
                        }else{
                            return(
                                <p key={index}>{item.text}</p>
                            )
                        }
                    })}
                    </h3>
                </div>
            </div>
        </div>
    </section>
    </>
    );
}

export default Aboutus;