import React from 'react';
import './style.css'
import { servicepriceDataee, servicepriceDataru } from './servicepricedata';
import Afterwordsservice from './bonusinfo';


function ServicePricetable({serviceID}){

    let pricearray = []

    let data = servicepriceDataru
    let index = 'ru'
    if((window.location.pathname).includes("/ee/")){
        data = servicepriceDataee
        index = 'ee'
    }

    data.map((item) =>{
        if(item.pricesID === serviceID){
            pricearray = item
        }
        return void 0
    })


    return(
    <> 
        <div className='sUnitprices'>
            <div className='sUnitpricesblockTitle'>{index === 'ru' ? 'Выберите подходящий для вас пакет' : 'Valige endale sobiv pakett'}</div>
            <div className='sUnittables'>
                {pricearray.content.map((item,index) => {
                    return(
                        <div key={index+3750} className='sUnittable'>
                            <div className='sUnittablepackegname'>{item.title}</div>
                            <ul className='sUnittableneed'>
                                <li className='sUnittabletitle'>{item.include}</li>
                                {item.includearray.map((item, index) => {
                                    return(
                                        <li key={index + 3650}><div>{item}</div></li>
                                    )
                                })}
                            </ul>
                            <ul className='sUnittableneed'>
                                <li className='sUnittabletitle'>{item.need}</li>
                            {item.needarray.map((item, index) => {
                                return(
                                    <li key={index+3550}><div>{item}</div></li>
                                )
                            })}
                            </ul>
                            {item.bonusinfo !== undefined ? <ul className='sUnittablebonusinfo'> {item.bonusinfo.map((item, index) => {
                                return(
                                <li key={index + 15000}>{item}</li>
                                )
                            })}</ul>: ''}
                        </div>
                    )
                })}
            </div>
            <div className='sUnitbonusinfo'>
                { pricearray.afterwords !== undefined ? <Afterwordsservice data={pricearray.afterwords}/> : 'false'}
            </div>
        </div>
    </>
    );
}


export default ServicePricetable;