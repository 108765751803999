import React from 'react';
import './style.css';
import { Headerdataru, Headerdataee } from './headerdata';
import {Carousel3} from '../carousel/carousel';
import { HashLink } from 'react-router-hash-link';

function Header(){
    let prefix = ''
    let data = Headerdataru

    if((window.location.pathname).includes("/ee")){
        prefix = '/ee'
        data = Headerdataee
    }

    return(
    <>
        <header className='headerblock'>
            <div className='headercontainer'>
                <div className='headerbox'>
                    <h1 className='headerbox_h1'>{data[0].firstheader}</h1>
                    {data[0].secondheader.map((item,index) => {
                        return(
                        <h2 key={index +37} className='headerbox_h2'>{item}</h2>
                        )
                    })}
                    <HashLink smooth to={prefix+'/aboutcompany'}><button className='headerbox_btn'>{data[0].btntext}</button></HashLink>
                </div>
            </div>
        </header>
        <div className='headerslider'>
            <Carousel3/>
        </div>
    </>
    );
}

export default Header;