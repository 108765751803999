import React from 'react';
import './style.css';
import { aboutcompanyDataru, aboutcompanyDataee } from './aboutcompanydata';
import Aboutcompanyfirstslide from './pageblocks/firstblock/firstblock';
import Aboutcompanysecondslide from './pageblocks/secondblock/secondblock';
import Aboutcompanythirdslide from './pageblocks/thirdblock/thirdblock';
import { Helmet } from 'react-helmet-async';

function Aboutcompany(){
    let aboutcompanyData
    let pagetitle

    if(/ee/.test(window.location.pathname)){
        aboutcompanyData = aboutcompanyDataee
        pagetitle = 'Meist'
    }else{
        aboutcompanyData = aboutcompanyDataru
        pagetitle = 'О нас'
    }
    
    return(
    <>
        <Helmet>
            <title>{pagetitle}</title>
            <meta name='description' content={aboutcompanyData[0]}/>
            <link rel='alternate' hreflang='et' href='https://greenera.ee/ee/aboutcompany' />
            <link rel='alternate' hreflang='ru' href='https://greenera.ee/aboutcompany' />
            <link rel="canonical" href={`https://greenera.ee` + window.location.pathname}/>
        </Helmet>

        <div className='aboutcompanyHeader'>
            <div className='aboutcompanyHeadertextbox'>
                <div className='aboutcompanyHeadertext'>
                    {aboutcompanyData[0]}
                </div>
            </div>
        </div>
        <div className='aboutcompanyMainblockContainer'>
            <Aboutcompanyfirstslide/>
            <Aboutcompanysecondslide/>
            <Aboutcompanythirdslide/>
        </div>
    </>
    );
}

export default Aboutcompany;