import React, { useState } from 'react';
import './style.css';
import { SidebarDataru, SidebarDataee } from './navbardata';
import { HashLink } from 'react-router-hash-link';

function Navbarlist(props){

    let Data

    if(props.lng === 'ee'){
        Data = SidebarDataee
    } else {
        Data = SidebarDataru
    }

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
    setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
    setDropdownVisible(false);
    };

    return(
    <>
    {Data.map((firstlistdata, index) => {
        if(firstlistdata.subMenu === undefined){
            return(
                <li key={index + 350} className='textContainer__item'><HashLink smooth to={firstlistdata.path}>{firstlistdata.title}</HashLink></li>
            )
        } else{
            return(
            <li key={index +300} className='textContainer__item' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {firstlistdata.title}
            <svg key={1400 + index} className='ulList' xmlns="http://www.w3.org/2000/svg" height="100%" width="10" viewBox="0 0 320 512">
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
            </svg>
            <ul key={index + 1300} className={isDropdownVisible ? 'dropdown_menu active' : 'dropdown_menu'}>
                {firstlistdata.subMenu.map((secondlistdata, index) =>{
                    return(
                        <HashLink key={index + 4050} to={secondlistdata.path}><li key={index + 450} className='textContainer__item'>{secondlistdata.title}</li></HashLink>
                    )
                })}
            </ul>
            </li>
            )
        }
    })}
    </>
    )
}

export default Navbarlist;