import instagram from './../../../photo/instagram.svg'
import facebook from './../../../photo/facebook.svg'
import mail from './../../../photo/envelope.svg'
import phone from './../../../photo/phone.svg'


export const contacticonData = [
    {
        uniqueID: 'Instagram',
        src: instagram,
        alt: 'instagram icon',
        data: 'ilonna.borissova',
        href: 'https://www.instagram.com/ilonna.borissova/'
    },
    {
        uniqueID: 'Facebook',
        src: facebook,
        alt: 'facebook address',
        data: 'Facebook',
        href: 'https://www.facebook.com'
    },
    {
        uniqueID: 'Email',
        src: mail,
        alt: 'mail address',
        data: 'info@greenera.com',
        href: 'mailto:info@greenera.com'
    },
    {
        uniqueID: 'Phone',
        src: phone,
        alt: 'phone number',
        data: '+372 53445404',
        href: 'tel:+37253445404'
    },
]

export const footerData = [
    {
        copyright: 'Greenera Copyright © 2023'
    }
]