export const servicepriceDataru = [
    {
        pricesID: 1,
        content: [
            {  
                title: 'Пакет START',
                need: 'Нужен вам, если:',
                include: 'Пакет включает в себя:',
                needarray: [
                    'Если вы купили участок, выбрали проект дома и нужно правильно расположить дом и другие постройки на участке.',
                    'У вас уже есть дом на участке, и вы не знаете, как грамотно расположить другие строения и определить расположение различных зон.',
                    'Если есть дом и дорожки, но вы хотите создать гармоничное пространство в саду.'
                ],
                includearray: [
                    'Выезд на участок и консультация',
                    '2 эскизных варианта планировочного решения',
                    'Подбор стилевого решения для проекта',
                    'Фотоаналоги'
                ],
                bonusinfo: [
                    'Стоимость 500 евро + нсо (размер участка до 15 соток)',
                    'Срок исполнения – 3 недели'
                ]
            },
            {
                title: 'Пакет OPTIMUM',
                need: 'Нужен вам, если:',
                include: 'Пакет включает в себя:',
                needarray: [
                    'Если вам нужен полный ландшафтный проект.',
                    'Если у вас есть участок и вам нужно разработать рабочие чертежи для реализации проекта.'
                ],
                includearray: [
                    'Выезд на участок и консультация',
                    '2 эскизных варианта планировочного решения (подбор стилевого решения для проекта, фотоаналоги) – 500 евро + нсо',
                    'Вертикальная планировка участка – 300 евро + нсо',
                    'Генеральный план (пояснительная записка, баланс площадей) – 300 евро + нсо',
                    'Разбивочный чертеж дорожек и площадок (пояснительная записка и подбор материалов) – 200 евро + нсо',
                    'Проект дренажной системы и ливневой канализации (по желанию) – 250 евро + нсо',
                    'Дендроплан (посадочный план, список растений, карта цветения растений, инструкции по уходу за растениями) – 400 евро + нсо',
                    'Проект автополива (по желанию) – 300 евро + нсо',
                    'План освещения (по желанию) – 250 евро + нсо',
                    'Визуализация (3-5 видов в технике скетч) – 200 евро + нсо'
                ]
            }
        ],
        afterwords: [
            'Выезд на консультацию 30 евро/час + нсо (консультация бесплатная в случае заключения договора).',
            'В пакете OPTIMUM возможны комбинации согласно потребностям заказчика.',
            'Мы осуществляем авторский надзор за ходом строительства и выполнением работ, связанных с ландшафтным дизайном и озеленением участка.',
            'Предоставляем обобщенную калькуляцию на реализацию проекта (стоимость растений, плитки для мощения, грунта и пр.), стоимость работ.',
            'Цены на услуги садовника устанавливаются индивидуально для каждого вида работ.'
        ]
    }
]

export const servicepriceDataee = [
    {
        pricesID: 1,
        content: [
            {  
                title: 'Pakett START',
                need: 'Teil on seda vaja,',
                include: 'Sisaldab:',
                needarray: [
                    'Kui ostsite krundi ja peate maja ja muud hooned krundil õigesti paigutama.',
                    'Kui maja on juba kohapeal ja te ei tea, kuidas teisi hooneid õigesti positsioneerida ja erinevate tsoonide asukohti määrata.',
                    'Kui teil on maja ja teed, kuid soovite luua aias harmoonilist ruumi.'
                ],
                includearray: [
                    'Objekti külastus ja konsultatsioon',
                    '2 eskiisplaneeringu lahendust',
                    'Projekti stiililahenduste valik',
                    'Foto analoogid'
                ],
                bonusinfo: [
                    'Maksumus 500 eurot + KM (krundi suurus kuni 15000m2)',
                    'Valmimisaeg - 3 nädalat'
                ]
            },
            {
                title: 'Pakett OPTIMUM',
                need: 'Teil on seda vaja,',
                include: 'Sisaldab:',
                needarray: [
                    'Kui vajate terviklikku haljastusprojekti',
                    'Kui on vaja projekti elluviimiseks välja töötada tööjoonised'
                ],
                includearray: [
                    'Objekti külastus ja konsultatsioon',
                    'Planeeringulahenduse 2 eskiisivarianti (projekti stiililahenduse valik, fotoanaloogid) – 500 eurot + KM',
                    'Vertikaalplaneering – 300 eurot + KM',
                    'Üldplaneering (seletuskiri, pindalad) – 300 eurot + KM',
                    'Teede paigutusjoonis (seletuskiri ja materjalide valik) – 200 eurot + KM',
                    'Drenaažisüsteemi ja sademekanalisatsiooni projekt (valikuline) – 250 eurot + KM',
                    'Dendroplan (istutusplaan, taimede nimekiri, õistaimede kaart, taimede hooldamise juhend) – 400 eurot +KM',
                    'Automaatkastmise projekt (valikuline) – 300 eurot + KM',
                    'Valgustusplaan (valikuline) – 250 eurot + KM',
                    'Visualiseerimine (3-5 tüüpi eskiistehnikas) – 200 eurot + KM'
                ]
            }
        ],
        afterwords: [
            'Konsultatsioonile väljasõit 30 eurot/tund + KM (lepingu sõlmimisel konsultatsioon tasuta).',
            'OPTIMUM paketis on võimalikud kombinatsioonid vastavalt kliendi vajadustele.',
            'Osutame järelevalvet ehituse edenemise ning maastikukujunduse ja objekti haljastusega seotud tööde teostamise üle.',
            'Anname projekti elluviimiseks üldistatud kalkulatsiooni (taimede maksumus, sillutusplaadid,  jne), tööde maksumus.',
            'Aednikuteenuste hinnad määratakse igale tööliigile individuaalselt.'
        ]
    }
]

