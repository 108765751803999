import React from 'react';
import './style.css';
import { aboutcompanyDataru, aboutcompanyDataee } from './../../aboutcompanydata';

function Aboutcompanythirdslide(){

    let aboutcompanyData

    if(/ee/.test(window.location.pathname)){
        aboutcompanyData = aboutcompanyDataee
    }else{
        aboutcompanyData = aboutcompanyDataru
    }

    return(
    <>
    <div className='ACfolder'>
        <div className='ACslide'>
            <div className='aboutcompanypaper'>
                <div className='ACTlist'>
                <div className='aboutcompanyendtext'>
                    <div className='aboutcompanyendtextbox'>
                        {aboutcompanyData[10]}
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default Aboutcompanythirdslide;