import React from 'react';
import './style.css';
import { serviceDataee,serviceDataru } from './servicesdata';
import { HashLink } from 'react-router-hash-link';


function Servicescard(props){

    let counet = 0
    let Data = serviceDataru

    if(props.lng === 'ee'){
        Data = serviceDataee
    }

    // const location = useLocation()

    // useEffect(() => {
        
    // }, [location])

    return(
    <>
    <div className='serviceCards'>
        {Data.map((item,index) => {
            if(counet%2 !== 0){
                counet = counet + 1
                return(
                    <div key={index+350} className='serviceCard'>
                        <img alt={item.subject} src={item.icon} className='serviceCardimg'/>
                        <div className='serviceCardtextandbtn'>
                            <div className='serviceCardText'>
                                <h2 className='serviceCardTexth2'>{item.subject}</h2>
                                {item.description.map((item,index)=>{
                                    return(
                                        <h3 key={index} className='serviceCardTexth3'>{item}</h3>
                                    )
                                })}
                            </div>
                            <HashLink to={item.path}><button className='serviceCardTexthbtn'>{props.lng === 'ru' ? 'Подробнее' : 'Rohkem infot'}</button></HashLink>
                        </div>
                    </div>
                ) 
            } else if(counet%2 === 0) {
                counet = counet + 1
                return(
                    <div key={index+350} className='serviceCard'>
                        <div className='serviceCardtextandbtn'>
                            <div className='serviceCardText'>
                                <h2 className='serviceCardTexth2'>{item.subject}</h2>
                                {item.description.map((item,index)=>{
                                    return(
                                        <h3 key={index} className='serviceCardTexth3'>{item}</h3>
                                    )
                                })}
                            </div>
                            <HashLink to={item.path}><button className='serviceCardTexthbtn'>{props.lng === 'ru' ? 'Подробнее' : 'Rohkem infot'}</button></HashLink>
                        </div>
                        <img alt={item.subject} src={item.icon} className='serviceCardimg'/>
                    </div>
                ) 
            } else{
                return void 0
            }
            
        })}
    </div>
    </>
    );
}

export default Servicescard;