export const aboutcompanyDataru = [
    'Если вашему участку не хватает руки профессионала, то мы готовы вам помочь. Мы найдем решение именно для вас!',
    'Дизайн природного окружения и интеграция человека в природу – основная идея в проектировании в нашей студии.',
    'Мы отошли от традиционных подходов к созданию ландшафтного дизайна, предпочитая экспериментировать и использовать необычные приемы. Это позволяет нам придавать уникальность нашим проектам и подходить к созданию ландшафтных решений с новой, креативной перспективой. Мы стремимся к тому, чтобы каждый проект стал уникальной экосистемой, олицетворяющей характер, вкусы и потребности наших клиентов.',
    'В своей работе мы уделяем особое внимание не только визуальному аспекту, но и функциональности, а также стремимся понять, какие уникальные особенности участка могут быть использованы в вашу пользу, какие растения будут наилучшим образом взаимодействовать друг с другом, создавая гармоничное и устойчивое окружение.',
    'Важным аспектом нашей миссии является также учет экологических и устойчивых решений. Мы стремимся создавать не только красивые, но и устойчивые к изменениям природы ландшафты. Разработка экосистемы учитывает биоразнообразие, использование эффективных систем орошения и дренажа, а также применение экологически чистых материалов.',
    'Таким образом, наша миссия превращает процесс ландшафтного дизайна в создание уникального, функционального и привлекательного пространства, которое не только отражает вашу индивидуальность, но и способствует гармонии с окружающей средой.',
    'Мы видим свою задачу в том, чтобы преобразовать ваш участок в уникальное пространство, насыщенное не только красотой, но и функциональностью.',
    'Кроме ландшафтного проектирования мы занимаемся реализацией своих проектов, предлагая комплексные решения для благоустройства и создания уникальных пространств. Наша команда специалистов включает в себя ландшафтных инженеров и опытных рабочих, готовых превратить концепции в реальность.',
    'Также наша команда оказывает услуги по уходу за садом, обеспечивая полный спектр услуг для создания и поддержания красивых и ухоженных садов. Наши опытные садовники готовы предложить вам индивидуальные решения, соответствующие вашим предпочтениям и потребностям.',
    'Кроме того, частичное проектирование или реконструкция сада тоже частый запрос, и мы это успешно решаем. А также рады предложить вам оформление балконов, террас и сезонное оформление любых зон.',
    'Наша цель - сделать ваш сад прекрасным местом для отдыха и наслаждения, а наши специалисты готовы воплотить ваши идеи в реальность, обеспечивая высокое качество услуг и заботы о вашем зеленом уголке.'
]
export const aboutcompanyDataee = [
    'Leiame lahenduse just sulle!',
    'Looduskeskkonna kujundamine ja inimese loodusesse integreerimine on meie stuudio disaini põhiidee.',
    '',
    '',
    'Oma töös pöörame erilist tähelepanu mitte ainult visuaalsele aspektile, vaid ka funktsionaalsusele.',
    'Meie ülesandeks on muuta teie aed ainulaadseks ruumiks, mis on küllastunud mitte ainult inimliku soojusega, vaid ka funktsionaalsusega.',
    '',
    'Lisaks maastikukujundusele teostame oma projekte, pakkudes terviklahendusi haljastuses ja omanäoliste ruumide loomiseks. Meie spetsialistide meeskonda kuuluvad maastikuinsenerid ja kogenud ehitajad, kes on valmis ideed ellu viima.',
    'Meie meeskond pakub ka aiahooldusteenuseid, pakkudes täisteenust kaunite ja hoolitsetud aedade loomiseks ja hooldamiseks. Meie kogenud aednikud on valmis pakkuma teile kohandatud lahendusi, mis vastavad teie eelistustele ja vajadustele.',
    'Lisaks on sagedane soov ka aia osaline projekteerimine või rekonstrueerimine ning selle lahendame edukalt. Meil on hea meel pakkuda teile ka rõdude, terrasside kujundust ja mistahes alade hooajalist kaunistamist.',
    'Meie eesmärk on muuta teie aed imeliseks kohaks puhkamiseks ja nautimiseks ning meie spetsialistid on valmis teie ideed ellu viima, pakkudes kvaliteetseid teenuseid ja hoolitsedes teie rohelise nurga eest.'
]