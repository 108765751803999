import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { serviceDataee,serviceDataru } from '../services/servicesdata';
import { portfolioData } from '../portfolio/portfoliodata';
import { lentcard, startlentcardarray} from './objects';
import { useLocation } from 'react-router-dom';

export function Carousel3(){
    let serviceData

    if((window.location.pathname).includes("/ee")){
        serviceData = serviceDataee
    }else{
        serviceData = serviceDataru
    }

    const location = useLocation()

    
    const [lentcardarray, setLentcardarray] = useState(startlentcardarray);
    const [animationshow, setAnimationshow] = useState(true)

    const nextcardnumber = useRef(5)
    const prevcardnumber = useRef(serviceData.length-2)
    const carouselstartposition = useRef([lentcardarray[0].position,lentcardarray[1].position,lentcardarray[2].position,lentcardarray[3].position,lentcardarray[4].position,lentcardarray[5].position,lentcardarray[6].position])
    const lentcardarrayref = useRef(startlentcardarray)

    useEffect(() => {
        nextcardnumber.current = 5
        prevcardnumber.current = serviceData.length-2
        if((window.location.pathname).includes("/ee/")){
            let array = [
                new lentcard(serviceData[serviceData.length-1].icon,serviceData[serviceData.length-1].subject, -400, 0),
                new lentcard(serviceData[0].icon,serviceData[0].subject,10, 1),
                new lentcard(serviceData[1].icon,serviceData[1].subject,420, 1),
                new lentcard(serviceData[2].icon,serviceData[2].subject,830, 1),
                new lentcard(serviceData[3].icon,serviceData[3].subject,1240, 1),
                new lentcard(serviceData[4].icon,serviceData[4].subject,1650, 1),
                new lentcard(serviceData[5].icon,serviceData[5].subject, 2060, 0)
            ]
            setLentcardarray(array)
            carouselstartposition.current = [array[0].position,array[1].position,array[2].position,array[3].position,array[4].position,array[5].position,array[6].position]
            lentcardarrayref.current = array
        }else{
            nextcardnumber.current = 5
            prevcardnumber.current = serviceData.length-2
            let array = [
                new lentcard(serviceData[serviceData.length-1].icon,serviceData[serviceData.length-1].subject, -400, 0),
                new lentcard(serviceData[0].icon,serviceData[0].subject,10, 1),
                new lentcard(serviceData[1].icon,serviceData[1].subject,420, 1),
                new lentcard(serviceData[2].icon,serviceData[2].subject,830, 1),
                new lentcard(serviceData[3].icon,serviceData[3].subject,1240, 1),
                new lentcard(serviceData[4].icon,serviceData[4].subject,1650, 1),
                new lentcard(serviceData[5].icon,serviceData[5].subject, 2060, 0)
            ]
            setLentcardarray(array)
            carouselstartposition.current = [array[0].position,array[1].position,array[2].position,array[3].position,array[4].position,array[5].position,array[6].position]
            lentcardarrayref.current = array 
        }
    }, [location, serviceData])

    function movenumberright(){
        if(nextcardnumber.current === serviceData.length-1){
            nextcardnumber.current = 0
        } else{
            nextcardnumber.current = nextcardnumber.current+1
        }
        if(prevcardnumber.current === serviceData.length-1){
            prevcardnumber.current = 0
        }else{
            prevcardnumber.current = prevcardnumber.current+1
        }
    }

    function movenumberleft(){
        if(nextcardnumber.current === 0){
            nextcardnumber.current = serviceData.length-1
        } else{
            nextcardnumber.current = nextcardnumber.current-1
        }

        if(prevcardnumber.current === 0){
            prevcardnumber.current = serviceData.length-1
        }else{
            prevcardnumber.current = prevcardnumber.current-1
        }
    }

    const slideLentRight = ()=>{


        const newlentcardarray = []
        lentcardarrayref.current.forEach((item,index)=>{
            if(item.position < 0){
                newlentcardarray.push(item)
                newlentcardarray[index].position = carouselstartposition.current[index] + 410
                newlentcardarray[index].opacity = 1
            } else if(item.position > 1 && item.position < 1600){
                newlentcardarray.push(item)
                newlentcardarray[index].position = carouselstartposition.current[index] + 410
            } else if(item.position === 1650){
                newlentcardarray.push(item)
                newlentcardarray[index].position = carouselstartposition.current[index] + 410
                newlentcardarray[index].opacity = 0
            } else if(item.position === 2060){
                const newprevcard = new lentcard(serviceData[prevcardnumber.current].icon,serviceData[prevcardnumber.current].subject, -400, 0)
                newlentcardarray.push(newprevcard)
            }
        })

        movenumberleft()
        lentcardarrayref.current = newlentcardarray
        setLentcardarray(newlentcardarray)
        carouselstartposition.current = [newlentcardarray[0].position,newlentcardarray[1].position,newlentcardarray[2].position,newlentcardarray[3].position,newlentcardarray[4].position,newlentcardarray[5].position,newlentcardarray[6].position]
        
    }

    const slideLentLeft = ()=>{

        const newlentcardarray = []
        movenumberright()

        lentcardarrayref.current.forEach((item,index)=>{
            if(item.position < 0){
                const newnextcard = new lentcard(serviceData[nextcardnumber.current].icon,serviceData[nextcardnumber.current].subject, 2060, 0)
                newlentcardarray.push(newnextcard)
            } else if(item.position > 11){
                newlentcardarray.push(item)
                newlentcardarray[index].position = carouselstartposition.current[index] - 410
                newlentcardarray[index].opacity = 1
            } else if(item.position === 10){
                newlentcardarray.push(item)
                newlentcardarray[index].position = carouselstartposition.current[index] - 410
                newlentcardarray[index].opacity = 0
            }
        })

        lentcardarrayref.current = newlentcardarray
        setLentcardarray(newlentcardarray)
        carouselstartposition.current = [newlentcardarray[0].position,newlentcardarray[1].position,newlentcardarray[2].position,newlentcardarray[3].position,newlentcardarray[4].position,newlentcardarray[5].position,newlentcardarray[6].position]
    }


    const testfunction = (e) =>{

        document.onmousemove = mousemoving;
        document.onmouseup = mouseendmoving;

        document.ontouchmove = mousemoving;
        document.ontouchend = mouseendmoving;

        setAnimationshow(false)
        let offset = 0;

        let isTouchEvent = e.type === "touchstart" ? true : false;

        let initialX = isTouchEvent ? e.touches[0].clientX : e.clientX;

        let repetitions = 0
        let secondarray

        function movearray(side, offset){

            const newlentcardarray = []
            const newpositionarray = []

            lentcardarrayref.current.forEach((item,index)=>{
                if((carouselstartposition.current[index] + (410 * side)) >= 2470){
                    const newcard = new lentcard(serviceData[prevcardnumber.current].icon,serviceData[prevcardnumber.current].subject, -400, 0)
                    newpositionarray.push(newcard.position)
                    newcard.position = -400 + offset
                    newlentcardarray.push(newcard)
                }else if((carouselstartposition.current[index] + (410 * side)) <= -810){
                    const newcard = new lentcard(serviceData[nextcardnumber.current].icon,serviceData[nextcardnumber.current].subject, 2060, 0)
                    newpositionarray.push(newcard.position)
                    newcard.position = 2060 + offset
                    newlentcardarray.push(newcard)
                }else{
                    newlentcardarray.push(item)
                    newlentcardarray[index].position = carouselstartposition.current[index] + offset + (410 * side)
                    newpositionarray.push(carouselstartposition.current[index] + (410 * side))
                }
            })
            lentcardarrayref.current = newlentcardarray
            carouselstartposition.current = newpositionarray
        }

        function movelent(number){
            const newarr = []

            lentcardarrayref.current.forEach((item, index)=>{
                newarr.push(item)
                newarr[index].position = carouselstartposition.current[index] + number
                
                if (carouselstartposition.current[index] + number < 2010 || carouselstartposition[index] + number > -370){
                    newarr[index].opacity = 1
                }else{
                    newarr[index].opacity = 0
                }
            })

            return newarr
        }


        function mousemoving(e){

            offset = (isTouchEvent ? e.touches[0].clientX : e.clientX) - initialX + (repetitions * 410)
            
            if(offset >= 410){ 
                repetitions--
                movearray(1, e.clientX - initialX + (repetitions * 410))
                movenumberleft()
            }else if(offset <= -410){
                movenumberright()
                repetitions++
                movearray(-1, e.clientX - initialX + (repetitions * 410))
            }else{
                secondarray = movelent(offset)
                lentcardarrayref.current = secondarray
                setLentcardarray(secondarray)
            }
        }

        function mouseendmoving(){
            setAnimationshow(true)
            if(offset >= 180 && offset > 0){
                const arrayforstop = []
                const arraypositionforstop = []
                lentcardarrayref.current.forEach((item, index) => {
                    if(carouselstartposition.current[index] + 410 === 2470){
                        const newcard = new lentcard(serviceData[prevcardnumber.current].icon,serviceData[prevcardnumber.current].subject, -400, 0)
                        arrayforstop.push(newcard)
                        arraypositionforstop.push(newcard.position)
                        movenumberleft()
                    }else {
                        arrayforstop.push(item)
                        arrayforstop[index].position = carouselstartposition.current[index] +410
                        arrayforstop[index].opacity = 1
                        arraypositionforstop.push(carouselstartposition.current[index]+410)
                    }
                });
                lentcardarrayref.current = arrayforstop
                carouselstartposition.current = arraypositionforstop
            }else if(offset <= -180 && offset < 0){
                
                const arrayforstop = []
                const arraypositionforstop = []

                lentcardarrayref.current.forEach((item, index) => {
                    if(carouselstartposition.current[index] - 410 === -810){
                        movenumberright()
                        const newcard = new lentcard(serviceData[nextcardnumber.current].icon,serviceData[nextcardnumber.current].subject, 2060, 0)
                        arrayforstop.push(newcard)
                        arraypositionforstop.push(newcard.position)
                    }else {
                        arrayforstop.push(item)
                        arrayforstop[index].position = carouselstartposition.current[index] -410
                        arrayforstop[index].opacity = 1
                        arraypositionforstop.push(carouselstartposition.current[index]-410)
                    }
                });
                lentcardarrayref.current = arrayforstop
                carouselstartposition.current = arraypositionforstop
            } else{
                const arrayforstop = []
                lentcardarrayref.current.forEach((item, index) => {
                    arrayforstop.push(item)
                    arrayforstop[index].position = carouselstartposition.current[index]
                })
            }

            document.onmousemove = null;
            document.onmouseup = null;
      
            document.ontouchmove = null;
            document.ontouchend = null;
        }
    }

    return(
        <>
        <div className='carouselBox'>
            <button className='caroseulbtn carouselbtnleft' onClick={slideLentRight}></button>
            <button className='caroseulbtn caroseulbtnright' onClick={slideLentLeft}></button>
            <div onMouseDown={testfunction} onTouchStart={testfunction}>
            {
                lentcardarrayref.current.map((item, index)=>{
                    const mystyle = {
                        transform: `translateX(${item.position}px)`,
                        opacity: item.opacity
                    }
                    return(
                        <div style={mystyle} key={100+index} className={animationshow ? 'carouselCardsa' : 'carouselCards'}>
                            <img src={item.image} alt={item.subject} loading='lazy' width="400" height="400"></img>
                            <div className='carouselCardtitle'>{item.subject}</div>
                        </div>
                    )
                })}
            </div>
        </div>
        </>
    )


}

export function ProtfolioSlider(){
    const [currnetcard, setCurrnetcard] = useState(0);

    let prefix = 'ru'

    if((window.location.pathname).includes("/ee")){
        prefix = 'ee'
    }

    const portfolioSlideleft = () => {
        if(currnetcard > 0){
            setCurrnetcard(currnetcard-1)
        }
    }
    const portfolioSlideright = () => {
        if(currnetcard < portfolioData.length-1){
            setCurrnetcard(currnetcard+1)
        }
    }

    const handlePointerEvent = (e) => {
        /* check which type of event we have, 
        and set a flag variable */
        let isTouchEvent = e.type === "touchstart" ? true : false;
    
        /* this is our card we will move */
        let card = e.target;
        /* to keep track of the value to offset the card left */
        let offset = 0;
        /* keeps the initial mouse click x value */
        let initialX = isTouchEvent ? e.touches[0].clientX - 25 : e.clientX - 25;
    
        /* mouse events */
        /* set the documents onmousemove event to use this function */
        document.onmousemove = onPointerMove;
        /* sets the documents onmouseup event to use this function */
        document.onmouseup = onPointerEnd;
    
        /* touch events */
        /* set the documents ontouchmove to this function */
        document.ontouchmove = onPointerMove;
        /* set the documents ontouchend to this function */
        document.ontouchend = onPointerEnd;
    
        /* when the mouse moves we handle the event here */
        function onPointerMove(e) {
          /* set offset to the current position of the cursor,
          minus the initial starting position  */
          offset = (isTouchEvent ? e.touches[0].clientX : e.clientX) - initialX;
          if (offset <= -100) {
            portfolioSlideright();
            /* if we're at the last card, snap back to center */
            if (currnetcard === portfolioData.length - 1) {
              card.style.left = 0;
            } else {
              /* hide the shift back to center 
            until after the transition */
              setTimeout(() => {
                card.style.left = null;
              }, 1000);
            }
            document.onmousemove = null;
            document.onmouseup = null;
      
            document.ontouchmove = null;
            document.ontouchend = null;
            return;
          }
          if (offset >= 115) {
            portfolioSlideleft();
            /* if we're at the first card, snap back to center */
            if (currnetcard === 0) {
              card.style.left = null;
            } else {
              /* hide the shift back to center 
            until after the transition */
              setTimeout(() => {
                card.style.left = null;
              }, 1000);
            }
            document.onmousemove = null;
            document.onmouseup = null;
      
            document.ontouchmove = null;
            document.ontouchend = null;
            return;
          }
          /* set the left style property of the card to the offset value */
          card.style.left = offset + "px";
        }
    
        function onPointerEnd(e) {
          /* if user releases mouse early,
          card needs to snap back */
          if (offset < 0 && offset > -100) {
            card.style.left = null;
          }
          if (offset > 0 && offset < 115) {
            card.style.left = null;
          }
          /* remove functions from event listeners
          (stop tracking pointer movements) */
          document.onmousemove = null;
          document.onmouseup = null;
    
          document.ontouchmove = null;
          document.ontouchend = null;
        }
      };

    return(
        <>
        <div className='portfolioCards'>
        {portfolioData.map((item, index) => {
            let index2 = index +410

            if(index === currnetcard){
                return(
                    <div onMouseDown={handlePointerEvent} onTouchStart={handlePointerEvent} key={index2} className='portfolioCard portcurrnetCard'>
                        <img loading='lazy' key={index} src={item.icon} alt={item.alt}/>
                    </div>
                )
            }else if(index === currnetcard-1){
                return(
                    <div key={index2} className='portfolioCard portfolio_prev'>
                        <img loading='lazy' key={index} src={item.icon} alt={item.alt}/>
                    </div>
                )
            }else if(index === currnetcard+1){
                return(
                    <div key={index2} className='portfolioCard portfolio_next'>
                        <img loading='lazy' key={index} src={item.icon} alt={item.alt}/>
                    </div>
                )
            }else if(index === currnetcard+2){
                return(
                    <div key={index2} className='portfolioCard portfolio_nexthidden'>
                        <img loading='lazy' key={index} src={item.icon} alt={item.alt}/>
                    </div>
                )
            }else if(index === currnetcard-2){
                return(
                    <div key={index2} className='portfolioCard portfolio_prevhidden'>
                        <img loading='lazy' key={index} src={item.icon} alt={item.alt}/>
                    </div>
                )
            }
            return void 0
        })}
        </div>
        <div className='portfolioSlider_btn'>
            {currnetcard === 0 ? '' : <button onClick={portfolioSlideleft}>&lt;&lt;  {prefix === 'ru' ? 'Назад' : 'Tagasi'}</button>}
            {currnetcard === portfolioData.length-1 ? '' : <button onClick={portfolioSlideright}>{prefix === 'ru' ? 'Вперед' : 'Edasi'}  &gt;&gt;</button>}
        </div>
        </>
    )
}