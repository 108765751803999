export const Headerdataru = [
    {
        firstheader: 'АВТОРСКИЙ ЛАНДШАФТНЫЙ ДИЗАЙН',
        secondheader: [
            'Создаем пространство комфорта для вашей жизни руками профессионалов.',
            'Добро пожаловать в нашу ландшафтную мастерскую!'
        ],
        btntext: 'Подробнее о нас'
    }
]

export const Headerdataee = [
    {
        firstheader: 'autori maastikukujundus',
        secondheader: [
            'Tere tulemast meie maastikustuudiosse!'
        ],
        btntext: 'Rohkem infot meist'
    }
]