import React, { useEffect, useState } from 'react';
import './style.css';
import { contacticonData, footerData } from './contactsdata';
import { useLocation } from 'react-router-dom';
import axios from 'axios';



function Contacts(){
    const [formsended, setFormsended] = useState(false);
    const [formerror, setFormerror] = useState(false);


    let index = 'ru'
    if((window.location.pathname).includes("/ee/")){
        index = 'ee'
    }


    const location = useLocation()

    useEffect(() => {
     
    }, [location])

    const sendEmail = (e) => {
        e.preventDefault();
        const data = {
            name: e.target.name.value,
            tel: e.target.tel.value,
            email: e.target.email.value,
            message: e.target.message.value
        }
        axios.post('https://api.greenera.ee/getmail', data)
        .then((res) => {
        console.log(res)
        if(res.data === 'sended'){
            setFormsended(true)
            setFormerror(false)
        }else if(res.data === 'notsended'){
            setFormerror(true)
            setFormsended(false)
        }
        })
    }

    return(
    <>
        <footer id='contactId' className='contactblock'>
            <div className='contactContainer'>
                <div className='contactTitle'>{index === 'ru' ? 'Контакты' : 'Kontaktid'}</div>
                <div className='contactMainbox'>
                    <div className='contactForm'>
                        <form onSubmit={sendEmail}>
                            <p className='contactformTitle'>{index === 'ru' ? 'Заявка на консультацию' : 'Kirjuta meile'}</p>
                            <input className='contactformInputs contactinputman' type='text' name='name' id='name' placeholder={index === 'ru' ? 'Имя' : 'Nimi'} required></input>
                            <input className='contactformInputs contactinputtel' type='tel' name='tel' id='tel' placeholder={index === 'ru' ? 'Телефон' : 'Telefon'} minLength='8' required></input>
                            <input className='contactformInputs contactinputmail' type='email' name='email' id='email' placeholder={index === 'ru' ? 'Эл. почта' : 'Email'} required></input>
                            {index === 'ru' ?
                             <textarea className='contactformTextarea contactinputcloud'  placeholder='Опишите ваш запрос' name='message' id='message' required></textarea> :
                             <textarea className='contactformTextarea contactinputcloud'  placeholder='Kirjeldage oma küsimust' name='message' id='message' required></textarea>
                            }
                            <button type="submit" className='contactformButton'>{index === 'ru' ? 'ОТПРАВИТЬ' : 'SAADA'}</button>
                            {formsended ? (index === 'ru' ? <p className='mailsend'> Ваша заявка успешно отправлена.</p> : <p className='mailsend'> Teie taotlus on edukalt esitatud.</p>) : ''}
                            {formerror ? (index === 'ru' ? <p className='mailsend'> Что-то пошло не так, попробуйте позже.</p> : <p className='mailsend'> Midagi läks valesti, proovige hiljem uuesti.</p>) : ''}
                        </form>
                    </div>
                    <div className='contactdatablock'>
                        <div className='contactdataTitle'>{index === 'ru' ? 'Свяжитесь с нами' : 'Võtke meiega ühendust'}</div>
                        <div className='contactdataList'>
                            <div className='contactdataUL'>
                                {contacticonData.map((item, index) => {
                                    let index2 = index+600
                                    return(
                                        <div key={index2} className='contactdataLi'>
                                            <div className='contactdataLiimg'><img src={item.src} alt={item.alt}></img></div>
                                            <div className='contactdataLitext'>
                                                <div className='contactdataLitextTitle'>{item.uniqueID+ ':'}</div>
                                                <div className='contactdataLitextMain'><a href={item.href}>{item.data}</a></div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footerContainer'>
                <div className='Copyright'>{footerData[0].copyright}</div>
            </div>
        </footer>
    </>
    );
}

export default Contacts;