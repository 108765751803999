import React, { useEffect, useState } from 'react';
import './style.css';
import Servicescard from './servicecardg';
import Servicescardlow from './servicecardlow';
import { useIsSsr } from './windowhook';


function Services(){

    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);

    useEffect(() => {
      const resizeW = () => changeDeviceSize(window.innerWidth);
  
      window.addEventListener("resize", resizeW); // Update the width on resize
  
      return () => window.removeEventListener("resize", resizeW);
    },[]);


    const isSsr = useIsSsr();
    if (isSsr) return null;

    let prefix = 'ru'

    if((window.location.pathname).includes("/ee/")){
        prefix = 'ee'
    }
  
    return(
    <>
        <section id='servicesId' className='servicesBlock'>
        <div className='serviceContainer'>
                <div className='serviceTitle'>
                    <h2 className='serviceHeader'>{prefix === 'ru' ? 'Наши услуги' : 'Meie teenused'}</h2>
                </div>
                <div className='serviceCards'>
                    {
                        deviceSize > 1030 ? <Servicescard lng={prefix}/> : <Servicescardlow lng={prefix}/>
                    }
                </div>
                
            </div>
        </section>
    </>
    );
}

export default Services;