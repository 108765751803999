import React, { useEffect, useState } from 'react';
import './style.css';
import Menu from './../../../photo/menu.png';
import { SidebarDataee, SidebarDataru } from './navbardata';
import { HashLink } from 'react-router-hash-link';
import Navbarlist from './navbarlist';
import Langswitch from './languageswitch';
import { useLocation } from 'react-router-dom';


function Navbar(){
    const [sidebar, setSidebar] = useState(false);

    const [isMobileDropdownVisible, setMobileDropdownVisible] = useState(false);

    const showSidebar = () => {setSidebar(!sidebar); setMobileDropdownVisible(false)}

    const location = useLocation()

    useEffect(() => {
        
    }, [location])


    let langData = 'ru'
    let prefix = '/'
    let Data = SidebarDataru

    if(/ee/.test(window.location.pathname)){
        langData = 'ee'
        prefix = '/ee/'
        Data = SidebarDataee
    }

    return(
    <>
    <nav className="nav">
        <div className='navSticky'>
        <div className='nav_row'>
        <a className='logocontainer' href={prefix}><div className='logo'></div></a>
            <ul key={3405} className='textContainer'>
            <Navbarlist lng={langData}/>
            </ul>
            <ul className='languageSwitch'>
                <Langswitch/>
            </ul>
            <button className='mobile_menu'><img src={Menu} alt='menu' onClick={showSidebar}/></button>
        </div>
        </div>
    </nav>
    <aside>
        <nav className='sidenav'>
            <div className={sidebar ? 'nav-menu__exiter active' : 'nav-menu__exiter'} onClick={showSidebar}></div>
            <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav_languageSwitch'>
                    <Langswitch/>
                </ul>
                <ul className='nav-bar__list'>
                {Data.map((firstlistdata, index) => {
                if(firstlistdata.subMenu === undefined){
                    return(
                        <li key={index + 1700} className='textContainer__item'><HashLink smooth to={firstlistdata.path}>{firstlistdata.title}</HashLink></li>
                    )
                } else{
                    return(
                    <li key={index + 1700} className='textContainer__item'
                    ><div onClick={() => {setMobileDropdownVisible(!isMobileDropdownVisible)}}>{firstlistdata.title}<svg className='ulList' xmlns="http://www.w3.org/2000/svg" height="100%" width="10" viewBox="0 0 320 512">\<path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg></div>
                        <ul className={isMobileDropdownVisible ? 'dropdown_menu--mobile drop_active' : 'dropdown_menu--mobile'}>
                            {firstlistdata.subMenu.map((secondlistdata, index) =>{
                                return(
                                    <li key={index + 1700} className='textContainer__item'><HashLink smooth to={secondlistdata.path}>{secondlistdata.title}</HashLink></li>
                                )
                            })}
                        </ul>
                    </li>
                    )
                }
            })}
                </ul>
            </div>
        </nav>
    </aside>
    </>
    );
}

export default Navbar;