import foto1 from './../../../photo/Portfolio/0502202401.webp'
import foto2 from './../../../photo/Portfolio/2-3.webp'
import foto3 from './../../../photo/Portfolio/0502202402.webp'
import foto4 from './../../../photo/Portfolio/1-3.webp'
import foto5 from './../../../photo/Portfolio/0502202403.webp'
import foto6 from './../../../photo/Portfolio/IMG_8450.webp'
import foto7 from './../../../photo/Portfolio/IMG_5-9576.webp'





export const portfolioData = [
    {
        icon: foto1,
        alt: 'never gonna give you up'
    },
    {
        icon: foto2,
        alt: 'never gonna give you up'
    },
    {
        icon: foto3,
        alt: 'never gonna give you up'
    },
    {
        icon: foto4,
        alt: 'never gonna give you up'
    },
    {
        icon: foto5,
        alt: 'never gonna give you up'
    },
    {
        icon: foto6,
        alt: 'never gonna give you up'
    },
    {
        icon: foto7,
        alt: 'never gonna give you up'
    }
]