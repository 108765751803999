import reportWebVitals from './reportWebVitals';
import React from 'react';
import App from './App';
import { hydrateRoot } from 'react-dom/client';
import ReactDOM from "react-dom/client";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));
if (rootElement.hasChildNodes()) {
  hydrateRoot (rootElement, <App />);
} else {
  root.render(<App />);
}

reportWebVitals();
