import React from 'react';
import './style.css';
import { aboutcompanyDataru, aboutcompanyDataee } from './../../aboutcompanydata';
function Aboutcompanyfirstslide(){
    let aboutcompanyData

    if(/ee/.test(window.location.pathname)){
        aboutcompanyData = aboutcompanyDataee
    }else{
        aboutcompanyData = aboutcompanyDataru
    }

    return(
    <>
    <div className='ACfolder'>
        <div className='ACslide'>
            <div className='aboutcompanypaper'>
                <div className='ACFmainblock'>
                    <div className='ACFtitletext'>
                        {aboutcompanyData[1]}
                    </div>
                    <div className='ACFmainblockinfo'>
                        <div className='ACFtextformat'>
                            {aboutcompanyData[2]}
                        </div>
                    </div>
                    <div className='ACFmainblockinfo ACFtextformat'>
                        {aboutcompanyData[3]}
                    </div>
                </div>
            </div>

            <div className='aboutcompanypaper'>
                <div className='ACSheaders'>
                    <div><h2>{aboutcompanyData[4]}</h2></div>
                    <div><h2>{aboutcompanyData[5]}</h2></div>
                </div>
                <div className='ACSblockendtext'>
                    <h2>{aboutcompanyData[6]}</h2>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default Aboutcompanyfirstslide;