import foto1 from './../../photo/Portfolio/1-1.webp'
import foto2 from './../../photo/Portfolio/1-2.webp'
import foto3 from './../../photo/Portfolio/1-3.webp'
import foto4 from './../../photo/Portfolio/1-4.webp'
import foto5 from './../../photo/Portfolio/1-6.webp'
import foto6 from './../../photo/Portfolio/1-7.webp'
import foto7 from './../../photo/Portfolio/2-1.webp'
import foto8 from './../../photo/Portfolio/2-2.webp'
import foto9 from './../../photo/Portfolio/2-3.webp'
import foto10 from './../../photo/Portfolio/2-4.webp'
import foto11 from './../../photo/Portfolio/2-5.webp'
import foto12 from './../../photo/Portfolio/2-6.webp'
import foto13 from './../../photo/Portfolio/2-7.webp'
import foto14 from './../../photo/Portfolio/2-8.webp'
import foto15 from './../../photo/Portfolio/2-9.webp'
import foto16 from './../../photo/Portfolio/2-10.webp'
import foto17 from './../../photo/Portfolio/2-11.webp'
import foto18 from './../../photo/Portfolio/2-12.webp'
import foto19 from './../../photo/Portfolio/2-13.webp'
import foto21 from './../../photo/Portfolio/3-1.webp'
import foto22 from './../../photo/Portfolio/3-2.webp'
import foto23 from './../../photo/Portfolio/3-3.webp'
import foto24 from './../../photo/Portfolio/3-4.webp'
import foto26 from './../../photo/Portfolio/4-2.webp'
import foto27 from './../../photo/Portfolio/4-3.webp'
import foto29 from './../../photo/Portfolio/4-5.webp'
import foto31 from './../../photo/Portfolio/4-7.webp'
import foto32 from './../../photo/Portfolio/4-8.webp'
import foto33 from './../../photo/Portfolio/IMG_6912.webp'
import foto34 from './../../photo/Portfolio/IMG_6917.webp'
import foto35 from './../../photo/Portfolio/IMG_7173.webp'
import foto36 from './../../photo/Portfolio/IMG_7209.webp'
import foto37 from './../../photo/Portfolio/IMG_7256.webp'
import foto38 from './../../photo/Portfolio/IMG_7270.webp'
import foto39 from './../../photo/Portfolio/IMG_7335.webp'
import foto40 from './../../photo/Portfolio/IMG_7677.webp'
import foto41 from './../../photo/Portfolio/IMG_8367.webp'
import foto42 from './../../photo/Portfolio/IMG_8394.webp'
import foto43 from './../../photo/Portfolio/IMG_8450.webp'
import foto62 from './../../photo/Portfolio/IMG_1901.webp'
import foto63 from './../../photo/Portfolio/IMG_1914.webp'
import foto64 from './../../photo/Portfolio/IMG_1916.webp'
import foto65 from './../../photo/Portfolio/IMG_1962.webp'
import foto66 from './../../photo/Portfolio/IMG_1967.webp'
import foto67 from './../../photo/Portfolio/IMG_2107.webp'
import foto68 from './../../photo/Portfolio/IMG_2110.webp'
import foto69 from './../../photo/Portfolio/IMG_7907.webp'
import foto44 from './../../photo/Portfolio/IMG_8459.webp'
import foto45 from './../../photo/Portfolio/IMG_8602.webp'
import foto46 from './../../photo/Portfolio/IMG_8709.webp'
import foto47 from './../../photo/Portfolio/IMG_8724.webp'
import foto48 from './../../photo/Portfolio/IMG_8818.webp'
import foto49 from './../../photo/Portfolio/IMG_8840.webp'
import foto50 from './../../photo/Portfolio/IMG_9150.webp'
import foto51 from './../../photo/Portfolio/IMG_9249.webp'
import foto52 from './../../photo/Portfolio/IMG_9391.webp'
import foto53 from './../../photo/Portfolio/IMG_9393.webp'
import foto54 from './../../photo/Portfolio/IMG_5-9523.webp'
import foto55 from './../../photo/Portfolio/IMG_5-9532.webp'
import foto56 from './../../photo/Portfolio/IMG_5-9542.webp'
import foto57 from './../../photo/Portfolio/IMG_5-9558.webp'
import foto58 from './../../photo/Portfolio/IMG_5-9576.webp'
import foto59 from './../../photo/Portfolio/IMG_5-9583.webp'
import foto60 from './../../photo/Portfolio/IMG_5-9593.webp'
import foto61 from './../../photo/Portfolio/IMG_5-9596.webp'
import foto70 from './../../photo/Portfolio/0502202401.webp'
import foto71 from './../../photo/Portfolio/0502202402.webp'
import foto72 from './../../photo/Portfolio/0502202403.webp'

export const portfoliopageData = [
    {
        icon: foto1,
        alt: 'portfolio image1'
    },
    {
        icon: foto2,
        alt: 'portfolio image1'
    },
    {
        icon: foto3,
        alt: 'portfolio image1'
    },
    {
        icon: foto4,
        alt: 'portfolio image1'
    },
    {
        icon: foto5,
        alt: 'portfolio image1'
    },
    {
        icon: foto6,
        alt: 'portfolio image1'
    },
    {
        icon: foto7,
        alt: 'portfolio image1'
    },
    {
        icon: foto8,
        alt: 'portfolio image1'
    },
    {
        icon: foto9,
        alt: 'portfolio image1'
    },
    {
        icon: foto10,
        alt: 'portfolio image1'
    },
    {
        icon: foto11,
        alt: 'portfolio image1'
    },
    {
        icon: foto12,
        alt: 'portfolio image1'
    },
    {
        icon: foto37,
        alt: 'portfolio image1'
    },
    {
        icon: foto38,
        alt: 'portfolio image1'
    },
    {
        icon: foto13,
        alt: 'portfolio image1'
    },
    {
        icon: foto14,
        alt: 'portfolio image1'
    },
    {
        icon: foto15,
        alt: 'portfolio image1'
    },
    {
        icon: foto16,
        alt: 'portfolio image1'
    },
    {
        icon: foto17,
        alt: 'portfolio image1'
    },
    {
        icon: foto18,
        alt: 'portfolio image1'
    },
    {
        icon: foto19,
        alt: 'portfolio image1'
    },
    {
        icon: foto21,
        alt: 'portfolio image1'
    },
    {
        icon: foto22,
        alt: 'portfolio image1'
    },
    {
        icon: foto23,
        alt: 'portfolio image1'
    },
    {
        icon: foto24,
        alt: 'portfolio image1'
    },
    {
        icon: foto26,
        alt: 'portfolio image1'
    },
    {
        icon: foto27,
        alt: 'portfolio image1'
    },
    {
        icon: foto29,
        alt: 'portfolio image1'
    },
    {
        icon: foto31,
        alt: 'portfolio image1'
    },
    {
        icon: foto32,
        alt: 'portfolio image1'
    },
    {
        icon: foto33,
        alt: 'portfolio image1'
    },
    {
        icon: foto34,
        alt: 'portfolio image1'
    },
    {
        icon: foto35,
        alt: 'portfolio image1'
    },
    {
        icon: foto36,
        alt: 'portfolio image1'
    },
    {
        icon: foto39,
        alt: 'portfolio image1'
    },
    {
        icon: foto40,
        alt: 'portfolio image1'
    },
    {
        icon: foto41,
        alt: 'portfolio image1'
    },
    {
        icon: foto42,
        alt: 'portfolio image1'
    },
    {
        icon: foto43,
        alt: 'portfolio image1'
    },
    {
        icon: foto67,
        alt: 'portfolio image1'
    },
    {
        icon: foto68,
        alt: 'portfolio image1'
    },
    {
        icon: foto69,
        alt: 'portfolio image1'
    },
    {
        icon: foto44,
        alt: 'portfolio image1'
    },
    {
        icon: foto62,
        alt: 'portfolio image1'
    },
    {
        icon: foto63,
        alt: 'portfolio image1'
    },
    {
        icon: foto64,
        alt: 'portfolio image1'
    },
    {
        icon: foto65,
        alt: 'portfolio image1'
    },
    {
        icon: foto66,
        alt: 'portfolio image1'
    },
    {
        icon: foto45,
        alt: 'portfolio image1'
    },
    {
        icon: foto46,
        alt: 'portfolio image1'
    },
    {
        icon: foto47,
        alt: 'portfolio image1'
    },
    {
        icon: foto48,
        alt: 'portfolio image1'
    },
    {
        icon: foto49,
        alt: 'portfolio image1'
    },
    {
        icon: foto50,
        alt: 'portfolio image1'
    },
    {
        icon: foto51,
        alt: 'portfolio image1'
    },
    {
        icon: foto52,
        alt: 'portfolio image1'
    },
    {
        icon: foto53,
        alt: 'portfolio image1'
    },
    {
        icon: foto54,
        alt: 'portfolio image1'
    },
    {
        icon: foto55,
        alt: 'portfolio image1'
    },
    {
        icon: foto56,
        alt: 'portfolio image1'
    },
    {
        icon: foto57,
        alt: 'portfolio image1'
    },
    {
        icon: foto58,
        alt: 'portfolio image1'
    },
    {
        icon: foto59,
        alt: 'portfolio image1'
    },
    {
        icon: foto60,
        alt: 'portfolio image1'
    },
    {
        icon: foto61,
        alt: 'portfolio image1'
    },
    {
        icon: foto70,
        alt: 'portfolio image1'
    },
    {
        icon: foto71,
        alt: 'portfolio image1'
    },
    {
        icon: foto72,
        alt: 'portfolio image1'
    }

]