import React, { useState } from 'react';
import './style.css'

function Serviceimage({array}){
    const [currentcard, Setcurrendcard] = useState(0);

    const previmg = () => {
        if(currentcard === 0){
            return void 0
        }else{
            Setcurrendcard(currentcard-1)
        }
    }
    const nextimg = () => {
        if(currentcard === array.length-1){
            return void 0
        }else{
            Setcurrendcard(currentcard+1)
        }
    }

    return(
    <> 
        <div className='sUnitimagecontainer'>
        {array.map((item, index) => {
            if(currentcard === index){
                return(
                    <img key={index + 1200} src={item} className='sUnitimgSizes' alt='service image1'></img>
                )
            }else{
                return(
                    <img key={index + 1200} src={item} className='sUnitimgSizes disabledimg' alt='service image1'></img>
                ) 
            }
        })}
        </div>
        <div className='sUnitimagebtns'> 
            <button onClick={previmg}>&lt;&lt;  Предыдущая</button> 
            <button onClick={nextimg}>Следующая  &gt;&gt;</button>
        </div>
    </>
    );
}


export default Serviceimage;