import React from 'react';
import { useParams } from 'react-router-dom';
import { serviceDataee,serviceDataru } from '../../mainpage/services/servicesdata';
import './style.css'
import Serviceimage from './serviceimage/serviceimage';
import ServicePricetable from './serviceprices/serviceprice';
import { Helmet } from 'react-helmet-async';

function Serviceunit(){
    let { id } = useParams();

    let serviceData
    let prefix
    
    if((window.location.pathname).includes("/ee")){
        serviceData = serviceDataee
        prefix = '/ee'
    }else{
        serviceData = serviceDataru
        prefix = ''
    }

    const serviceUnit = serviceData.filter((array) => array.path === `${prefix}/services/${id}`)
    let imagecontainer = 'sUnitdisabled';

    if(serviceUnit[0].bonusfoto !== undefined){
        imagecontainer = 'sUnitdisplay'
    }

    let myStyle = {
        backgroundImage: `url('${serviceUnit[0].unithat}')`
    }



    return(
    <> 
        <Helmet>
            <title>{serviceUnit[0].subject}</title>
            <meta name='description' content={serviceUnit[0].description}/>
            <link rel='alternate' hreflang='et' href={`https://greenera.ee/ee/services/${id}`} />
            <link rel='alternate' hreflang='ru' href={`https://greenera.ee/services/${id}`} />
            <link rel="canonical" href={`https://greenera.ee` + window.location.pathname}/>
        </Helmet>
        <section className='serviceunit'>
        <div style={myStyle} className='serviceunittop'/>
            <div className='sUnitmainbox'>
                <div className='sUnitTitle'>
                    {serviceUnit[0].subject}
                </div>
                <div className='sUnitmaininfo'>
                    <div className='sUnittextandfoto'>
                        <div className='sUnittext'>
                            {serviceUnit[0].service.map((item, index) => {
                                return(
                                <div key={index} className='sUnitservicetext'>{item}</div>
                                )
                            })}
                        </div>
                        <div className={imagecontainer === 'sUnitdisabled' ? 'sUnitimg sUnitdisabled' : 'sUnitimg sUnitdisplay'}>
                            {imagecontainer === 'sUnitdisabled' ? '' : <Serviceimage array={serviceUnit[0].bonusfoto}/>}
                        </div>
                    </div>
                </div>
                {serviceUnit[0].pricesID !== undefined ? <ServicePricetable serviceID={serviceUnit[0].pricesID}/> : ''}
            </div>
        </section>
    </>
    );
}


export default Serviceunit;