import React from 'react';
import './style.css';



function Waveblock({rotate}){

    if(rotate === false){
        return(
            <>
                <div className='wave'></div>
            </>
        );
    } else if(rotate === true){
        return(
            <>
                <div className='waverotate'></div>
            </>
        );
    }

}

export default Waveblock;