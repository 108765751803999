import React from 'react';
import Header from './header/header';
import Advantages from './advantages/advantage';
import Waveblock from './wave/wave';
import Services from './services/services';
import Aboutus from './aboutus/aboutus';
import Portfolio from './portfolio/portfolio';
import { Helmet } from 'react-helmet-async';


function Mainpage(){

    let prefix =''
    let pagetitle = ''
    let metadescription = ''

    if((window.location.pathname).includes("/ee")){
        prefix = '/ee'
    }

    if(prefix === ''){
        pagetitle = 'Greenera - Авторский Ландшафтный Дизайн в Эстонии'
        metadescription = 'Создаем пространство комфорта для вашей жизни руками профессионалов. Добро пожаловать в нашу ландшафтную мастерскую! | Таллинн, Эстония'
    }else if (prefix === '/ee'){
        pagetitle = 'Autori maastikukujundus Eestis'
        metadescription = 'Loo oma elu mugavuse ruumi professionaalide kätega. Tere tulemast meie maastikustuudiosse! | Tallinn, Eesti'
    }
    return(
    <>
        <Helmet>
            <title>{pagetitle}</title>
            <meta name='description' content={metadescription}/>
            <link rel='alternate' hreflang='et' href='https://greenera.ee/ee/' />
            <link rel='alternate' hreflang='ru' href='https://greenera.ee/' />
            <link rel="canonical" href={`https://greenera.ee` + window.location.pathname}/>
        </Helmet>
            <Header />
            <Advantages/>
            <Waveblock rotate={false}/>
            <Services/>
            <Waveblock rotate={true}/>
            <Aboutus/>
            <Waveblock rotate={false}/>
            <Portfolio/>
            <Waveblock rotate={true}/>
    </>
    );
}

export default Mainpage;