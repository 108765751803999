export const SidebarDataru = [
    {
        title: 'ПРЕИМУЩЕСТВА',
        path: '/#advantageclass',
    },
    {
        title: 'НАШИ УСЛУГИ',
        path: '/#servicesId',
        subMenu: [
            {
                title: 'проектирование',
                path: '/services/Design',
            },
            {
                title: 'Озеленение и благоустройтсво',
                path: '/services/Landscaping',
            },
            {
                title: 'Уход за садом',
                path: '/services/Garden_care',
            },
            {
                title: 'Консультация и дистанционное проектирование',
                path: '/services/Consultation',
            },
            {
                title: 'Сезонное оформление',
                path: '/services/Seasonal_decoration',
            },
            {
                title: 'Устройство рулонного газона',
                path: '/services/Rolled_lawn',
            },
            
        ]
    },
    {
        title: 'О НАС',
        path: '/#aboutusID',
    },
    {
        title: 'ПОРТФОЛИО',
        path: '/#portfolioId',
    },
    {
        title: 'КОНТАКТЫ',
        path: '/#contactId',
    }
]

export const SidebarDataee = [
    {
        title: 'EELISED',
        path: '/ee/#advantageclass',
    },
    {
        title: 'MEIE TEENUSED',
        path: '/ee/#servicesId',
        subMenu: [
            {
                title: 'Projekteerimine',
                path: '/ee/services/Design',
            },
            {
                title: 'Haljastus',
                path: '/ee/services/Landscaping',
            },
            {
                title: 'Aiahooldus',
                path: '/ee/services/Garden_care',
            },
            {
                title: 'Konsultatsioon ja kaugprojekteerimine',
                path: '/ee/services/Consultation',
            },
            {
                title: 'Hooajaline kaunistus',
                path: '/ee/services/Seasonal_decoration',
            },
            {
                title: 'Rullmuru paigaldus',
                path: '/ee/services/Rolled_lawn',
            },
            
        ]
    },
    {
        title: 'MEIST',
        path: '/ee/#aboutusID',
    },
    {
        title: 'PORTFOLIO',
        path: '/ee/#portfolioId',
    },
    {
        title: 'KONTAKTID',
        path: '/ee/#contactId',
    }
]