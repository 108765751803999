import React from 'react';
import './style.css';
import { serviceDataee, serviceDataru } from './servicesdata';
import { HashLink } from 'react-router-hash-link';


function Servicescardlow(props){

    let Data = serviceDataru

    if(props.lng === 'ee'){
        Data = serviceDataee
    }


    return(
    <>
    <div className='serviceCards'>
        {Data.map((item,index) => {
            return(
                <div key={index+350} className='serviceCard'>
                    <img alt={item.subject} src={item.icon} className='serviceCardimg'/>
                    <div className='serviceCardtextandbtn'>
                        <div className='serviceCardText'>
                            <h2 className='serviceCardTexth2'>{item.subject}</h2>
                            {item.description.map((item,index)=>{
                                    return(
                                        <h3 key={index} className='serviceCardTexth3'>{item}</h3>
                                    )
                            })}
                        </div>
                        <HashLink to={item.path}><button className='serviceCardTexthbtn'>{props.lng === 'ru' ? 'Подробнее' : 'Rohkem infot'}</button></HashLink>
                    </div>
                </div>
            ) 
        })}
    </div>

    </>
    );
}

export default Servicescardlow;