import { serviceDataee,serviceDataru } from '../services/servicesdata';

 let serviceData

if((window.location.pathname).includes("/ee")){
    serviceData = serviceDataee
}else{
    serviceData = serviceDataru
}


export function lentcard(image, subject, position, opacity) {
    this.image = image;
    this.subject = subject;
    this.position = position;
    this.opacity = opacity
}

const nextcardnumber = 5;
const prevcardnumber = serviceData.length-1;

const prevcard = new lentcard(serviceData[prevcardnumber].icon,serviceData[prevcardnumber].subject, -400, 0)
const firstcard = new lentcard(serviceData[0].icon,serviceData[0].subject,10, 1)
const secondcard = new lentcard(serviceData[1].icon,serviceData[1].subject,420, 1)
const thirdcard = new lentcard(serviceData[2].icon,serviceData[2].subject,830, 1)
const fourthcard = new lentcard(serviceData[3].icon,serviceData[3].subject,1240, 1)
const fifthcard = new lentcard(serviceData[4].icon,serviceData[4].subject,1650, 1)
const nextcard = new lentcard(serviceData[nextcardnumber].icon,serviceData[nextcardnumber].subject, 2060, 0)

export const startlentcardarray = [prevcard, firstcard, secondcard, thirdcard, fourthcard, fifthcard, nextcard]

export const startlentcardarrayru = [
    new lentcard(serviceDataru[prevcardnumber].icon,serviceDataru[prevcardnumber].subject, -400, 0),
    new lentcard(serviceDataru[0].icon,serviceDataru[0].subject,10, 1),
    new lentcard(serviceDataru[1].icon,serviceDataru[1].subject,420, 1),
    new lentcard(serviceDataru[2].icon,serviceDataru[2].subject,830, 1),
    new lentcard(serviceDataru[3].icon,serviceDataru[3].subject,1240, 1),
    new lentcard(serviceDataru[4].icon,serviceDataru[4].subject,1650, 1),
    new lentcard(serviceDataru[nextcardnumber].icon,serviceDataru[nextcardnumber].subject, 2060, 0)
]

export const startlentcardarrayee = [
    new lentcard(serviceDataee[prevcardnumber].icon,serviceDataee[prevcardnumber].subject, -400, 0),
    new lentcard(serviceDataee[0].icon,serviceDataee[0].subject,10, 1),
    new lentcard(serviceDataee[1].icon,serviceDataee[1].subject,420, 1),
    new lentcard(serviceDataee[2].icon,serviceDataee[2].subject,830, 1),
    new lentcard(serviceDataee[3].icon,serviceDataee[3].subject,1240, 1),
    new lentcard(serviceDataee[4].icon,serviceDataee[4].subject,1650, 1),
    new lentcard(serviceDataee[nextcardnumber].icon,serviceDataee[nextcardnumber].subject, 2060, 0)
]