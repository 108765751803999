import React from 'react';
import './style.css'


function Afterwordsservice({data}){

    return(
    <> 
       <div className='sUnitafterwords'>
        <ul>
            {data.map((item, index) => {
                return(
                    <li key={index+14050}>{item}</li>
                )
            })}
        </ul>
       </div>
    </>
    );
}




export default Afterwordsservice;