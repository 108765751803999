import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Langswitch(){

    const [lang, setLang] = useState(2);

    const location = useLocation()

    useEffect(() => {
        
    }, [location])

    let activelang = ''
    let pageName = window.location.pathname

    if (pageName.includes("/ee")) {
      pageName = pageName.slice(3)
      activelang = 'ee'
    }else{
        activelang = 'ru'
    }
  
    let eePagePath = `/ee${pageName}`

    if(activelang === 'ru'){
        return(
            <>
                <li onClick={()=>{setLang(lang-1)}} ><HashLink to={eePagePath}>ee</HashLink></li>
                <li className='languageactive'>ru</li>
            </>
        );
    }else {
        return(
            <>
                <li className='languageactive'>ee</li>
                <li onClick={()=>{setLang(lang+1)}} ><HashLink to={pageName}>ru</HashLink></li>
            </>
            );
    }
}

export default Langswitch;